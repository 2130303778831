<template>
  <div class="about">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"
      :bgcolor="BannerKvData.bgcolor"/>
    <AboutIntroduction
      :titleEn="AboutIntroductionData.titleEn"
      :titleZh="AboutIntroductionData.titleZh"
      :intro="AboutIntroductionData.intro"
      :items="AboutIntroductionData.items"/>
    <AboutDoctorIntroduction
      :titleEn="AboutDoctorIntroductionData.titleEn"
      :titleZh="AboutDoctorIntroductionData.titleZh"
      :info="AboutDoctorIntroductionData.info"
      :link="AboutDoctorIntroductionData.link"
      :decoPic="AboutDoctorIntroductionData.decoPic"/>
    <AboutDoctors/>
    <AboutMore
      :items="AboutMoreData.items"/>
  </div>
</template>

<script>
import '@/assets/scss/about.scss';
import BannerKv from '@/components/BannerKv.vue';
import AboutIntroduction from '@/components/about/AboutIntroduction.vue';
import AboutDoctorIntroduction from '@/components/about/AboutDoctorIntroduction.vue';
import AboutDoctors from '@/components/about/AboutDoctors.vue';
import AboutMore from '@/components/about/AboutMore.vue';
// eslint-disable-next-line
import { getLabsArticles, getLabsTeamsContent } from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

import bannerKvPic from '@/assets/img/About_NUWA/service_3.jpg';
import pic01 from '@/assets/img/index/imglink_1.jpg';
import more01 from '@/assets/img/about/more_1_new.jpg';
import more02 from '@/assets/img/about/more_2_new.jpg';

export default {
  name: 'aboutLab',
  components: {
    BannerKv,
    AboutIntroduction,
    AboutDoctorIntroduction,
    AboutDoctors,
    AboutMore,
  },
  data() {
    return {
      BannerKvData: {
        titleEn: 'NUWA LAB',
        titleZh: this.$t('nuwaLabs'),
        backgroundPic: bannerKvPic,
        bgcolor: '#f8f8f8',
      },
      AboutIntroductionData: {
        titleEn: 'About Lab',
        titleZh: this.$t('labsTitle'),
        intro: this.$t('labsIntro'),
        items: [],
      },
      AboutDoctorIntroductionData: {
        titleEn: 'DOCTOR<br>INTRODUCTION',
        titleZh: this.$t('labsTeamsTitle'),
        info: this.$t('labsTeamsIntro'),
        link: '/staff/labs/l1',
        decoPic: pic01,
      },
      AboutMoreData: {
        items: [
          {
            link: '/about/ivf',
            pic: more01,
            titleEn: 'NUWA IVF CENTER',
            titleZh: this.$t('nuwaIvf'),
            info: this.$t('aboutIvfIntro'),
          },
          {
            link: '/about/tcm',
            pic: more02,
            titleEn: 'NUWA TCM CENTER',
            titleZh: this.$t('tcmTitle'),
            info: this.$t('aboutTcmIntro'),
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    getData() {
      this.AboutIntroductionData.items = [];
      getLabsArticles(
        this.getLangUUID,
        (response) => {
          response.data.data.forEach((elem) => {
            const temp = {
              pic: elem.icon,
              title: elem.title,
              brief_info: elem.brief_info,
              type: 'link',
              parameter: {
                class: 'labs',
                id: elem.slug,
              },
            };
            this.AboutIntroductionData.items.push(temp);
          });
        },
      );
      getLabsTeamsContent(
        this.getLangUUID,
        (response) => {
          console.log(response);
          console.log(response.data.data);
          this.AboutDoctorIntroductionData = {
            titleEn: 'DOCTOR<br>INTRODUCTION',
            titleZh: response.data.data.title,
            info: response.data.data.content,
            link: '/staff/procreation/l1',
            decoPic: response.data.data.image,
          };
        },
      );
    },
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        this.getData();
      }
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      this.getData();
    }
  },
};
</script>
